import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';

export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages &
  Sitecore.Override.ItemEx[];

export type PriorAuthLookupAuthTrueResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup &
  ResultMessages &
  any;

const PriorAuthLookupAuthResultsForm = ({
  resultMessage,
  authFormData,
}: PriorAuthLookupAuthTrueResultsProps) => {
  const defaultResultsTitle = resultMessage?.fields?.titleLabel?.value;
  const iconImageSrc = resultMessage?.fields?.iconImage?.value?.src;

  return (
    <div className={classNames('text-left', 'pb-12', 'pt-8', 'lg:px-12')}>
      <div className={classNames('pb-4', 'flex', 'items-center', 'space-x-4')}>
        <ImageWrapper
          field={{
            value: {
              src: iconImageSrc,
              width: '32px',
              height: '28px',
            },
          }}
        />{' '}
        &nbsp;
        <Heading
          headingText={{ value: defaultResultsTitle }}
          headingClassName={classNames('font-family')}
          headingLevel="h2"
          headingSize="sm"
          descriptionClassName="mb-8"
          headingIsRichText
        />
      </div>

      <p>
        <span className="font-bold">Group Number:</span> {authFormData.GroupNumber}
      </p>
      <p>
        <span className="font-bold">Procedure Code:</span> {authFormData.ProcedureCode?.CPTCode}
      </p>
      <br />
      <p>
        <span className="font-bold">Description:</span> {authFormData.ProcedureCode?.Description}
      </p>
      <br />
      <p>
        <span className="font-bold italic">Code included in Gold Card Program?</span>{' '}
        {authFormData.GoldCard}
      </p>
      <br />

      <p>
        <span className="font-bold italic">Site of Service Review Required?</span>{' '}
        {authFormData.SiteOfService}
      </p>
      <br />
      <p>
        <span className="font-bold italic">Request Prior Authorization from?</span>{' '}
        {authFormData.AuthManager}
      </p>
      <br />

      <div className={classNames('rte', 'mb-4', 'mt-4', 'text-sm', 'italic')}>
        <RichText field={{ value: resultMessage?.fields?.messageText?.value }} editable={false} />
      </div>
    </div>
  );
};

export default PriorAuthLookupAuthResultsForm;

import React from 'react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Heading from 'components/helpers/Heading/Heading';
import { DevLog } from 'lib/utils/logging-utils';

export type PriorAuthLookupAuthFalseResultsProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup &
  any;

export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;

const PriorAuthLookupAuthFalseResults = ({
  fields,
  resultMessage,
  responseData,
}: PriorAuthLookupAuthFalseResultsProps) => {
  DevLog('PriorAuthLookupAuthFalseResults fields = ', fields);
  DevLog('PriorAuthLookupAuthFalseResults resultMessage = ', resultMessage);
  DevLog('PriorAuthLookupAuthFalseResults responseData = ', responseData);
  return (
    <div className={classNames('text-left', 'pb-12', 'pt-2', 'px-2', 'md:px-12')}>
      {/* Heading Section */}
      <div className={classNames('pb-10', 'flex', 'items-center', 'space-x-4')}>
        <ImageWrapper
          field={{
            value: {
              src: resultMessage?.fields?.iconImage?.value?.src,
              width: '25px',
              height: '25px',
            },
          }}
          imageClass="w-[25px] h-[25px] flex-shrink-0 object-contain"
        />
        <Heading
          headingText={{ value: resultMessage?.fields?.titleLabel?.value }}
          headingClassName={classNames('font-family', 'text-black')}
          headingLevel="h2"
          headingSize="sm"
          descriptionClassName="mb-8"
          headingIsRichText
        />
      </div>
      {/* Group Number and CPT Code Section */}
      <div>
        <p className={classNames('text-black')}>
          <span className="font-bold">Group Number:</span> {responseData?.GroupNumber}
        </p>
        <p className={classNames('mb-6', 'text-black')}>
          <span className="font-bold">CPT Code:</span> {responseData?.ProcedureCode?.CPTCode}
        </p>
      </div>

      {/* RichText Section */}
      <div className={classNames('rte', 'mb-4', 'mt-4', 'text-sm', 'text-black')}>
        <RichText field={{ value: resultMessage?.fields?.messageText?.value }} editable={false} />
      </div>
    </div>
  );
};

export default PriorAuthLookupAuthFalseResults;

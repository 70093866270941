export const AuthErrorCodes = {
  AUTH_FAILURE: 'AUTH_FAILURE', // 404
  CPT_ERROR: 'CPT_ERROR', // 501
  AUTH_FALSE: 'AUTH_FALSE', // 401
  AUTH_TRUE: 'AUTH_TRUE', // 200
  AUTH_NULL_DEFAULT: 'AUTH_NULL_DEFAULT',
  AUTH_NULL_BAD_PREFIX: 'AUTH_NULL_BAD_PREFIX', // 4041
  AUTH_NULL_BAD_MEMBER_ID: 'AUTH_NULL_BAD_MEMBER_ID', // 4042
  AUTH_NULL_INACTIVE: 'AUTH_NULL_INACTIVE', // 4043
  AUTH_CHS_CODE_VALIDATOR: 'AUTH_CHS_CODE_VALIDATOR', // 502
  AUTH_IAZ_CODE_VALIDATOR: 'AUTH_IAZ_CODE_VALIDATOR', // 503
};

export const AuthResults = {
  DEFAULT: 'DEFAULT',
  CPT_CODE_VALIDATION: 'CPT_CODE_VALIDATION',
  CHS_CODE_VALIDATION: 'CHS_CODE_VALIDATION',
  IAZ_CODE_VALIDATION: 'IAZ_CODE_VALIDATION',
  AUTH_NULL: 'AUTH_NULL',
  AUTH_TRUE: 'AUTH_TRUE',
  AUTH_FALSE: 'AUTH_FALSE',
};

export const AuthMessages = {
  INVALID_PREFIX: 'Invalid Prefix',
  INVALID_MEMBER_ID: 'Invalid MemberId',
  MEMBER_NOT_ACTIVE: 'Member is not active',
};
